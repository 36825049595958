<template>
  <div @click="onClickTableSearch">
    <AGTooltip :tooltipText="label" :disabled="showTooltip">
      <template slot:tooltipArea>
        <v-text-field
          v-model="model"
          :label="label"
          :ref="id"
          :id="id"
          :disabled="isDisabled"
          class="ag-search-input"
          :style="styleCustom"
          prepend-icon="mdi-magnify"
          dense
          clearable
          rounded
          hide-details
          @click:clear="onClear()"
          @focus="onFocus()"
          @blur="onBlur()"
        >
        </v-text-field>
      </template>
    </AGTooltip>
  </div>
</template>

<script>
import { uniqueId, debounce } from 'lodash';

export default {
  name: 'TableSearch',

  components: {
    AGTooltip: () => import('./AGTooltip.vue'),
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: uniqueId('table_search_'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styleCustom: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      default: '',
    },
    delay: {
      type: Number,
      default: 1250,
    },
  },

  data() {
    return {
      inputFocused: false,
    };
  },

  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.updateValueDebounced(value);
      },
    },
    updateValueDebounced() {
      return debounce(this.emitInput, this.delay);
    },
    isDisabled() {
      return this.disabled;
    },
    lengthForLabelTest() {
      if (self.innerWidth < 1920) {
        return 60;
      }
      return 93;
    },
    lengthForLabelTestResized() {
      if (self.innerWidth < 1920) {
        return 75;
      }
      return 116;
    },
    showTooltip() {
      if (
        (!this.labelResized && this.label.length <= this.lengthForLabelTest) ||
        (this.labelResized && this.label.length <= this.lengthForLabelTestResized)
      ) {
        return true;
      }
      return false;
    },
    labelResized() {
      return (![null, undefined].includes(this.model) && this.model.length > 0) || this.inputFocused;
    },
  },

  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
    onClickTableSearch() {
      this.$refs[this.id].focus();
    },
    onClear() {
      this.$emit('click:clear');
    },
    onFocus() {
      this.inputFocused = true;
    },
    onBlur() {
      this.inputFocused = false;
    },
  },
};
</script>
