var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { on: { click: _vm.onClickTableSearch } },
    [
      _c(
        "AGTooltip",
        { attrs: { tooltipText: _vm.label, disabled: _vm.showTooltip } },
        [
          [
            _c("v-text-field", {
              ref: _vm.id,
              staticClass: "ag-search-input",
              style: _vm.styleCustom,
              attrs: {
                label: _vm.label,
                id: _vm.id,
                disabled: _vm.isDisabled,
                "prepend-icon": "mdi-magnify",
                dense: "",
                clearable: "",
                rounded: "",
                "hide-details": "",
              },
              on: {
                "click:clear": function ($event) {
                  return _vm.onClear()
                },
                focus: function ($event) {
                  return _vm.onFocus()
                },
                blur: function ($event) {
                  return _vm.onBlur()
                },
              },
              model: {
                value: _vm.model,
                callback: function ($$v) {
                  _vm.model = $$v
                },
                expression: "model",
              },
            }),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }